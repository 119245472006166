<template lang="pug">
v-col.pa-0(v-if='Pie_de_pagina')
 
    //-VISTA PC
    v-container(fluid).d-none.d-md-block.containerPC
        //-logo
        v-row.justify-center.align-center    
            v-col(cols=12 md=2).justify-center.align-center    
                v-img(:src='Pie_de_pagina.logo'  height='100px' contain)
            v-col(cols=12 md=2).justify-center.align-center    

                p(v-html="Pie_de_pagina.correo" ).white--text.font-italic.justify-center.align-center.font-weight-light   
                h3(v-html="Pie_de_pagina.numero"    style="line-height: 0.2em ").white--text.font-italic  

        //- Horario
        v-row.justify-center.align-center.my-4
    
            v-card(  flat color='#490383').pa-3.card
                h2(v-html='Pie_de_pagina.horario.titulo').text-center.texto_verde.font-italic
                //- Lunes-viernes
                p(v-html='Pie_de_pagina.horario.lunes_viernes').text-center.white--text.font-italic
                p(v-html='Pie_de_pagina.horario.horaLV').text-center.white--text.horario.font-weight-light.font-italic

                //- sabado
                p(v-html='Pie_de_pagina.horario.sabado').text-center.white--text.font-italic
                span(v-html='Pie_de_pagina.horario.horaS').text-center.white--text.horario.font-weight-light.font-italic
                
        //-recorrido
        v-row.justify-center.align-center.my-4
            v-btn(href='/inicio').transparent.elevation-0(style="text-transform: capitalize; ")
                h4.white--text.font-weight-medium Nosotros
            v-btn(href='/servicios').transparent.elevation-0(style="text-transform: capitalize; ")
                h4.white--text.font-weight-medium Especialidades
            v-btn(href='/especialistas').transparent.elevation-0(style="text-transform: capitalize; ")
                h4.white--text.font-weight-medium Staff Médico
            v-btn(href='/citas').transparent.elevation-0(style="text-transform: capitalize; ")
                h4.white--text.font-weight-medium Citas
            v-btn(href='/contacto').transparent.elevation-0(style="text-transform: capitalize; ")
                h4.white--text.font-weight-medium Contacto

        v-row.row_derechos
            v-col(cols=12 md=12 )
                v-row.justify-center
                    //- DERECHOS COPY
                    v-col.text-center(cols=12 md=8 ).font-weight-thin.caption.white--text
                        | {{ new Date().getFullYear() }} — 
                        span.white--text(v-html='Pie_de_pagina.derechos').font-weight-light
                    //- REDES SOCIALES
                    v-col(cols=12 md=4).justify-center(v-if='Navegador')    
                        i(v-for='Navegador, i of Navegador.redes_sociales' :key='`Red${i}`'  style="font-size: 20px; cursor: pointer; color: white"
                        :class='Navegador.icon' @click='redirigir_a(Navegador.enlace)').mr-3.ml-3.justify-center.align-center
      
    //- __________________________________________
     //- VISTA MOVIL
    .d-md-none
       
        v-container(fluid style="background-color: #37236C")
            //-logo
            v-row.justify-center.align-center    
            
                v-col.justify-center.align-center    
                    v-img(:src='Pie_de_pagina.logo'  height='80px' contain)
                v-col  
                    p(v-html="Pie_de_pagina.correo" ).white--text.font-italic.font-weight-light.text-center.horario_movil_size
                    h3(v-html="Pie_de_pagina.numero"    style="line-height: 0.2em ").white--text.font-italic.text-center.horario_movil_size
            //- Horario
            v-row.justify-center.align-center.my-4
                v-col(cols=10 )
                    v-card(  flat color='#490383' style="border-radius: 30px; ").pa-1
                        
                        h3(v-html='Pie_de_pagina.horario.titulo' style="color: #00AA7F").text-center.font-italic.mt-2
                        //- Lunes-viernes
                        p(v-html='Pie_de_pagina.horario.lunes_viernes').text-center.white--text.font-italic.horario_movil_size
                        p(v-html='Pie_de_pagina.horario.horaLV').text-center.white--text.horario.font-weight-ligth.font-italic.horario_movil_size

                        //- sabado
                        p(v-html='Pie_de_pagina.horario.sabado').text-center.white--text.font-italic.horario_movil_size
                        p(v-html='Pie_de_pagina.horario.horaS').text-center.white--text.horario.font-italic.font-weight-ligth.horario_movil_size
                    
           
            //- REDES SOCIALES
            v-row.justify-center(v-if='Navegador').pb-4    
                i(v-for='Navegador, i of Navegador.redes_sociales' :key='`Red${i}`'  style="font-size: 25px; cursor: pointer; color: white"
                :class='Navegador.icon' @click='redirigir_a(Navegador.enlace)').mr-3.ml-3.justify-center.align-center
            v-row.justify-center.align-center.row_derechos
                v-col
                    v-row.justify-center
                        //- DERECHOS COPY
                        v-col.text-center(cols=12 ).font-weight-thin.caption.white--text.font-weight-light.font-italic
                            | {{ new Date().getFullYear() }} — 
                            span.white--text(v-html='Pie_de_pagina.derechos')
                       
            
    
</template>
<script>
    export default {
        methods: {
            redirigir_a(enlace){
                window.open(enlace, '_blank')
            },
        },
    }
</script>
<style lang="sass" scoped>
.horario_movil_size
    font-size: 14px
.containerPC
    height: 100%
    background-color: #37236C
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-size: cover
.containercolorpiemovil
    height: 330px
    background-color: var(--secondary)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-size: cover
.verd
    height: 30px
    background-color: #FFFFFF !important

.card     
    border-radius: 20px
    width: 400px
.texto_verde
    color: #00AA7F
    

.horario
    line-height: 0em

.row_derechos
    background-color: #490383

</style>